














































































































































































import Vue from 'vue';

import { PostMeRequest } from '@/model/Request';
import { memoruAlbumsModule } from '@/store/dataModules/memoruAlbumsModule';
import { auth0Module } from '@/store/dataModules/auth0Module';
import LocalDataService from '@/service/LocalDataService';
import { MeNurserySchoolResponse, MultimediaAlbumResponse } from 'chaild-api/lib';
import dayjs from 'dayjs';
import ApiMemoru from '@/api/ApiMemoru';
import { nurseryModule } from '@/store/dataModules/nurseryModule';

interface DataType {
  isCreateDialogOpen: boolean;
  newAlbumYear: number;
  newAlbumTitle: string;
  newAlbumClassIds: Array<string | number>;
  newAlbumComment: string;
  newAlbumAvailableAfter: string | null;
  isPublishNow: boolean;
  newAlbumAvailableBefore: string | null;
  isPublicForever: boolean;
  isCreateInClosed: boolean;
  nurseryId: number | null;
  isCreating: boolean;
  isFetching: boolean;
  listType: number;
}

export default Vue.extend({
  computed: {
    getFiscalYear() {
      const month = dayjs().month();
      const year = dayjs().year();
      if (month < 3) {
        this.newAlbumYear = year - 1;
        return this.newAlbumYear
      } else {
        return this.newAlbumYear
      }
    },
    albums(): MultimediaAlbumResponse[] {
      const albums = memoruAlbumsModule.albums

      if (this.listType === 1) {
        return this.publishedAlbums
      }

      if (this.listType === 2) {
        return this.unpublishedAlbums
      }

      return albums
    },
    allAlbums(): MultimediaAlbumResponse[] {
      return memoruAlbumsModule.albums
    },
    publishedAlbums(): MultimediaAlbumResponse[] {
      return memoruAlbumsModule.albums.filter((album) => {
        const diff1 = dayjs().diff(dayjs(album.availableAfter), 'day')
        if (album.availableBefore) {
          const diff2 = dayjs().diff(dayjs(album.availableBefore), 'day')
          return diff1 >= 0 && diff2 < 0
        }
        return diff1 >= 0;
      })
    },
    unpublishedAlbums(): MultimediaAlbumResponse[] {
      return memoruAlbumsModule.albums.filter((album) => {
        const diff1 = dayjs().diff(dayjs(album.availableAfter), 'day')
        if (album.availableBefore) {
          const diff2 = dayjs().diff(dayjs(album.availableBefore), 'day')
          return diff1 < 0 || diff2 >= 0
        }
        return diff1 < 0;
      })
    },
    hasMoreAlbums(): boolean {
      if (this.albums.length < memoruAlbumsModule.total) {
        return true;
      }
      return false;
    },
    classes() {
      return nurseryModule.allClasses;
    },
    availableAfterValue() {
      if (this.isCreateInClosed || this.isPublishNow) {
        return dayjs().toISOString();
      }
      return this.newAlbumAvailableAfter
    },
    availableBeforeValue() {
      if (this.isCreateInClosed) {
        return dayjs().toISOString();
      }
      if (this.isPublicForever) {
        return null
      }
      return this.newAlbumAvailableBefore
    }
  },
  data: (): DataType => {
    return {
      isCreateDialogOpen: false,
      newAlbumYear: dayjs().year(),
      newAlbumTitle: '',
      newAlbumClassIds: [],
      newAlbumComment: '',
      newAlbumAvailableBefore: null,
      newAlbumAvailableAfter: null,
      isPublishNow: false,
      isPublicForever: false,
      isCreateInClosed: false,
      nurseryId: null,
      isCreating: false,
      isFetching: false,
      listType: 0
    }
  },
  methods: {
    async create() {
      if (this.nurseryId && this.availableAfterValue) {
        this.isCreating = true;
        const response = await ApiMemoru.createAlbum({
          nurseryId: this.nurseryId,
          year: this.newAlbumYear,
          title: this.newAlbumTitle,
          comment: this.newAlbumComment,
          nurseryClassIds: this.newAlbumClassIds,
          availableAfter: this.availableAfterValue,
          availableBefore: this.availableBeforeValue
        })
        this.isCreating = false;
        this.isCreateDialogOpen = false;

        memoruAlbumsModule.listAlbums(this.nurseryId);
      }
    },
    clearCreateValues() {
      this.newAlbumTitle = '';
      this.newAlbumClassIds = [];
      this.newAlbumComment = '';
      this.newAlbumAvailableAfter = null;
      this.newAlbumAvailableBefore = null;
    },
    getClasses() {
      if (this.nurseryId && this.newAlbumYear) {
        nurseryModule.getNurseryClassWithYear({
          nurseryId: this.nurseryId,
          year: this.newAlbumYear
        })
      }
    },
    loadMore() {
      if (this.nurseryId) {
        memoruAlbumsModule.listMoreAlbums(this.nurseryId);
      }
    },
  },
  async mounted() {
    const user = LocalDataService.getUser();
    if (user) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const nurseryId = nurserySchool.nurseryId;
      this.nurseryId = nurseryId;
      this.isFetching = true;
      await Promise.all([
        memoruAlbumsModule.listAlbums(nurseryId),
        this.getClasses()
      ])
      this.isFetching = false;
    }
  },
  watch: {
    newAlbumYear() {
      this.getClasses();
    }
  }
});
