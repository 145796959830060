


































































































import Vue from 'vue';

import DatePager from '@/components/molecules/DatePager.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import LocalDataService from '@/service/LocalDataService';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import { childrenModule } from '@/store/dataModules/childrenModule';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import { DiaryResponse, NurseryChildResponse, NurseryClassResponse } from 'chaild-api/lib';
dayjs.locale(ja);
export default Vue.extend({
  components: {
    DatePager,
  },
  data: () => {
    return {
      btnLoading: false,
      contentLoading: false,
      date: '' as any,
      tabIndex: 0,
      time: '',
      content: '特記事項なし',
      childDiary: null as any,
      isRecordDialog: false,
      otherRecord: '',
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      classId: 0,
      childId: 0,
      nurseryId: 0,
      selectedClass: '' as any,
      year: dayjs().year(),
      month: dayjs().month(),
      dialogFlg: false,
      loadFlg: false,
      childList: [] as any,
      classList: [] as any,
      windowHistory: '' as any,
    };
  },
  computed: {
    clas(): NurseryClassResponse | null {
      if (nurseryModule.clas) {
        this.selectedClass = nurseryModule.clas;
        return nurseryModule.clas;
      }
      return null;
    },
    child(): NurseryChildResponse | null {
      if (childrenModule.child) {
        return childrenModule.child;
      }
      return null;
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
        this.selectClassItems();
      },
      deep: true,
    },
  },
  methods: {
    routerBack() {
      const routes = window.history.length
      console.log(routes)
      if (this.windowHistory === '') {
        if(routes > 1) {
          this.$router.back()
        } else {
          const classId = this.$route.params.classId;
          this.$router.push(`/record/class/${classId}`);
        }
      } else {
        const classId = this.windowHistory;
        this.$router.push(`/record/class/${classId}`);
      }
    },
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    openSelectChildDialog() {
      this.dialogFlg = true;
      this.selectedClass = this.clas;
      this.selectChildItems();
    },
    //クラス情報取得（開いている個人日誌の日付を元に取得）
    async selectClassItems() {
      this.classList = [];
      this.month = dayjs(this.date).month();
      this.year = dayjs(this.date).year();
      if (this.month < 3) {
        this.year = this.year - 1;
      }
      if (this.nurseryId && this.year) {
        const classList = nurseryModule.getNurseryClassWithYear({
          nurseryId: this.nurseryId,
          year: this.year
        })
        this.classList = (await classList);
      }
      if (this.classList.length === 0) {
        this.classList.push({
          classId: 0,
          className: 'クラスが設定されておりません。',
        });
      }
    },
    //選択されているクラスに登録されている園児情報取得
    async selectChildItems() {
      this.childList = [];
      this.loadFlg = true;
      if (this.selectedClass.year == this.year) {
        const listData =  childrenModule.getChildren({
          nurseryId:this.nurseryId,
          classId: this.selectedClass.classId,
        });

        const arr = (await listData).children;
        const len = arr.length;
        for (let i = 0; i < len; i++) {
          this.childList.push({
            childId: arr[i].childId,
            name: arr[i].lastName + arr[i].firstName,
          })
        }
        this.loadFlg = false;
        return this.childList;
      } else {
        this.loadFlg = false;
      }
    },
    //選択した園児の個人日誌のページに遷移
    toClassChildrenRecords(child: NurseryChildResponse) {
      if (this.windowHistory === '') {
        this.windowHistory = this.$route.params.classId;
      }

      //現在開いている園児以外を選択した時のみ this.$router.push を行う
      const childId = `${child.childId}`;
      if (this.$route.params.childId != childId) {
        this.$router.push({
          path: `/record/class/${this.selectedClass.classId}/child/${child.childId}`,
          query: { date: `${this.date.toISOString()}` }
        });
      }

      this.init();
      this.dialogFlg = false;
    },
    saveContent: async function() {
      if (this.content) {
        this.btnLoading = true;
        if (this.childDiary && !this.childDiary.error) {
          try {
            const response = await diaryReportModule.updateChildDiary({
              childId: this.childId.toString(10),
              diaryId: this.childDiary.diaryId.toString(10),
              content: this.content,
            });
            this.childDiary = response;
            this.snackBarText = '保存しました';
            this.snackbar = true;
          } catch {}
        } else {
          try {
            const response = await diaryReportModule.createChildDiary({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
              content: this.content,
            });
            this.childDiary = response;
            this.snackBarText = '保存しました';
            this.snackbar = true;
          } catch {}
        }
        this.btnLoading = false;
      }
    },
    init: async function() {
      try {
        // this.contentLoading = true
        const user = LocalDataService.getUser();
        const classId = this.$route.params.classId;
        const childId = this.$route.params.childId;
        this.classId = parseInt(classId, 10);
        this.childId = parseInt(childId, 10);
        if (user) {
          let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
          if (!nurserySchool) {
            nurserySchool = user.nurserySchools[0];
          }
          const nurseryId = nurserySchool.nurseryId;
          this.nurseryId = nurseryId;
          Promise.all([
            nurseryModule.getNurseryClassDetail({
              nurseryId,
              classId: this.classId,
            }),
            childrenModule.getChild(this.childId),
          ]);
          await diaryReportModule.fetchChildDiary({
            childId: this.childId,
            date: this.date.format('YYYY-MM-DD'),
          });
          this.content = '特記事項なし'
          if (diaryReportModule.childDiary) {
            this.childDiary = await diaryReportModule.childDiary;
            if (diaryReportModule.childDiary.content) {
              this.content = diaryReportModule.childDiary.content;
            }
          }
        }
      } catch {
      } finally {
        // this.contentLoading = false
      }
    },
  },
  async created() {
    const date = this.$route.query.date as string
    if (date) {
      const temp = dayjs(date);
      this.date = temp
    } else {
      this.date = dayjs();
    }
  },
  async mounted() {
    // const localText = LocalDataService.getDiaryText();
    // if (localText) {
    //   this.content = localText;
    // }
    this.init();
  },
  beforeDestroy() {
    childrenModule.refreshChild();
    nurseryModule.refreshClass();
    // if (this.content && !this.childDiary.content) {
    //   LocalDataService.setDiaryText(this.content);
    // } else {
    //   LocalDataService.removeDiaryText();
    // }
  },
});
